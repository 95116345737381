var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-10 mt-lg-20"},[_c('h2',{staticClass:"auth-title mb-5 mb-lg-8"},[_vm._v("Welcome to your project portal.")]),_c('form',{staticClass:"form w-100",attrs:{"novalidate":"novalidate"}},[(!_vm.form.secret)?_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"fw-semi-bold mb-2"},[_vm._v(" Secret "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: 'You can find this in your email.',
            delay: { show: 400, hide: 0 },
            placement: 'top',
          }),expression:"{\n            content: 'You can find this in your email.',\n            delay: { show: 400, hide: 0 },\n            placement: 'top',\n          }"}],staticClass:"fs-6 text-info bi bi-info-circle ms-2"})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.secret),expression:"form.secret"}],class:['form-control form-control-solid bg-white h-50px', _vm.validateModel(_vm.$v.form.secret.$error)],attrs:{"type":"text"},domProps:{"value":(_vm.form.secret)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "secret", $event.target.value)}}})]):_vm._e(),_c('div',{staticClass:"mb-10"},[_c('label',{staticClass:"fw-semi-bold mb-2"},[_vm._v(" Code "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: 'You can find this in your email.',
            delay: { show: 400, hide: 0 },
            placement: 'top',
          }),expression:"{\n            content: 'You can find this in your email.',\n            delay: { show: 400, hide: 0 },\n            placement: 'top',\n          }"}],staticClass:"fs-6 text-info bi bi-info-circle ms-2"})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.code),expression:"form.code"}],class:['form-control form-control-solid bg-white h-50px', _vm.validateModel(_vm.$v.form.code.$error)],attrs:{"type":"text"},domProps:{"value":(_vm.form.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "code", $event.target.value)}}})]),_c('div',{staticClass:"d-flex"},[_c('px-btn',{attrs:{"size":"lg","in-process":_vm.sendInProcess,"disabled":_vm.sendInProcess},nativeOn:{"click":function($event){return _vm.onSend.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.sendInProcess ? 'Please wait...' : 'Submit')+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }