<template>
  <div class="mt-10 mt-lg-20">
    <h2 class="auth-title mb-5 mb-lg-8">Welcome to your project portal.</h2>
    <form class="form w-100" novalidate="novalidate">
      <div v-if="!form.secret" class="mb-4">
        <label class="fw-semi-bold mb-2">
          Secret
          <i
            class="fs-6 text-info bi bi-info-circle ms-2"
            v-tooltip="{
              content: 'You can find this in your email.',
              delay: { show: 400, hide: 0 },
              placement: 'top',
            }"
          />
        </label>
        <input
          v-model="form.secret"
          :class="['form-control form-control-solid bg-white h-50px', validateModel($v.form.secret.$error)]"
          type="text"
        />
      </div>
      <div class="mb-10">
        <label class="fw-semi-bold mb-2">
          Code
          <i
            class="fs-6 text-info bi bi-info-circle ms-2"
            v-tooltip="{
              content: 'You can find this in your email.',
              delay: { show: 400, hide: 0 },
              placement: 'top',
            }"
          />
        </label>
        <input
          v-model="form.code"
          :class="['form-control form-control-solid bg-white h-50px', validateModel($v.form.code.$error)]"
          type="text"
        />
      </div>
      <div class="d-flex">
        <px-btn size="lg" :in-process="sendInProcess" :disabled="sendInProcess" @click.native="onSend">
          {{ sendInProcess ? 'Please wait...' : 'Submit' }}
        </px-btn>
        <!--        <button type="button" class="btn btn-lg btn-primary" :data-kt-indicator="indicator" @click="onSend">-->
        <!--          <span class="indicator-label">Submit</span>-->
        <!--          <span class="indicator-progress">-->
        <!--            Please wait...-->
        <!--            <span class="spinner-border spinner-border-sm align-middle ms-2" />-->
        <!--          </span>-->
        <!--        </button>-->
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { required } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      // indicator: 'off',
      form: {
        secret: null,
        code: null,
      },

      sendInProcess: false,
    };
  },
  validations: {
    form: {
      secret: { required },
      code: { required },
    },
  },
  computed: {
    ...mapGetters({
      message: 'projectPreview/message',
      projectData: 'projectPreview/projectData',
    }),
  },
  mounted() {
    this.form.secret = this.$route.query?.secret || null;
  },
  methods: {
    ...mapActions({
      actionInitOverall: 'projectPreview/initOverall',
    }),
    async onSend() {
      console.log(1);

      this.sendInProcess = true;

      this.$v.form.$touch();

      // this.indicator = 'on';

      if (this.$v.form.$anyError) {
        // this.indicator = 'off';
        this.sendInProcess = false;

        return false;
      }

      await this.actionInitOverall({ ...this.form });

      // this.indicator = 'off';

      if (this.projectData) {
        await this.$router.push({
          name: 'project-preview',
          params: { id: this.form.secret },
          query: { code: this.form.code },
        });
      }

      this.sendInProcess = false;
    },
  },
};
</script>
